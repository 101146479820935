<template>
  <CContainer class="d-flex content-center min-vh-100">
    <CRow>
      <CCol>
        <CCardGroup>
          
           <CCard class="p-4" style="min-width:300px; max-width:300px; background-color:#3c2468; border:none" 
            text-color="white">
            <CCardBody>
              <CForm v-show="mostrarCambioPass">
                <h1 style="color:white; font-size:26px;">Reportes Vapp</h1>
                <p class="text-muted" style="color:white">Cambiar Contraseña</p>
                <!--<CInput
                v-show="mostrarCorreoUser"
                  placeholder="Correo"
                  autocomplete="Correo"
                  v-model="mailUser"
                  @keyup.enter.native="cambiarPass"
                  @blur="validarCampos(usuario)"
                ></CInput>-->
                <CInput
                  placeholder="Contraseña nueva"
                  autocomplete="Contraseña nueva"
                  type="password"
                  v-model="passwd"
                  :is-valid="passValidada"
                  @blur="validarPssw"
                  @keyup.enter.native="cambiarPass"
                ></CInput>
                <CInput
                  placeholder="Repetir contraseña"
                  autocomplete="Repetir contraseña"
                  type="password"
                  v-model="passwdRpt"
                  :is-valid="passValidadaRpt"
                  @blur="validarPsswRpt"
                  @keyup.enter.native="cambiarPass"
                ></CInput>
                <CRow v-show="mostrarMsjCambio">
                    <CCol col="12" class="text-rigth">
                        <CAlert :color="tipoMensaje" style="max-width:250px">{{msjCambio}}</CAlert>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol col="12" class="text-rigth">
                        <CButton style="background-color:#0b7689;color:white;" class="px-4" :disabled="!passValidada || !passValidadaRpt" @click="cambiarPass">Cambiar</CButton>
                    </CCol>
                </CRow>
              </CForm>
              <CForm v-show="!mostrarCambioPass">
                  <CAlert :color="tipoMensaje" style="max-width:250px">{{msjCambiarPass}}</CAlert>
                  <CRow>
                    <CCol col="12" class="text-rigth">
                        <CButton style="background-color:#0b7689;color:white;" class="px-4" @click="volverLogin">Volver a Login</CButton>
                    </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>
        </CCardGroup>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
  import cons from '@/const'
  import axios from "axios"

  export default {
    name: 'Login',
    
    data () {
        return {

            regPass: /^(?=.*\d)(?=.*[\u0021-\u002b\u003c-\u0040])(?=.*[A-Z])(?=.*[a-z])\S{8,30}$/,
               
            //mailUser: "",
            passwd: "",
            passwdRpt: "",
            
            //mostrarCorreoUser: false,
            passValidada: false,
            passValidadaRpt: false,

            mostrarCambioPass: true,
            mostrarMsjCambio: false,
            msjCambiarPass: "",
            msjCambio: "",
            tipoMensaje: "success",

        }
    },
    beforeMount() {
        if(this.$route.query.token == null || this.$route.query.token == ""){
            this.mostrarCambioPass = false;
            this.msjCambiarPass = "No se proporsiono información de Sesión para cambiar contraseña"
        }
      
    },
    methods: {
        validarPssw: function(){
            
            var espacios = false;
            var cont = 0;

            while (!espacios && (cont < this.passwd.length)) {
            if (this.passwd.charAt(cont) == " ")
                espacios = true;
                cont++;
            }
            
            if (espacios) {
                //alert ("La contraseña no puede contener espacios en blanco");
                this.$notification.error("Ups! La contraseña no puede contener espacios en blanco", {  timer: 10, position:"bottomRight"});
                this.passValidada = false;
            }
            else if (!this.regPass.test(this.passwd)) {
                //alert("Los campos de la password no pueden quedar vacios");
                this.$notification.error("Ups! La contraseña debe ser de al menos 8 caracteres, debe contener al menos una mayuscula, un numero y un caracter especial", {  timer: 10, position:"bottomRight"});
                this.passValidada = false;
            }
            else{
                this.passValidada = true;
            }
        },
        validarPsswRpt: function(){
            if(this.passValidada){
                var espacios = false;
                var cont = 0;

                while (!espacios && (cont < this.passwdRpt.length)) {
                if (this.passwdRpt.charAt(cont) == " ")
                    espacios = true;
                    cont++;
                }
                
                if (espacios) {
                    //alert ("La contraseña no puede contener espacios en blanco");
                    //this.$notification.error("Ups! La contraseña no puede contener espacios en blanco", {  timer: 10, position:"bottomRight"});
                    this.passValidadaRpt = false;
                }
                else if (this.passwd != this.passwdRpt) {
                    //alert("Las passwords deben de coincidir");
                    this.$notification.error("Las contraseñas deben de coincidir", {  timer: 10, position:"bottomRight"});
                    this.passValidadaRpt = false;
                }
                else{
                    this.passValidadaRpt = true;
                }
            }
        },
        cambiarPass: function(){
            var tokenUser = this.$route.query.token;
            
            if(this.passValidada && this.passValidadaRpt){

                var url = cons.port+"://" + cons.ipServer + "/cambiar_pass_user/"

                /*this.tipoMensaje = "success"
                this.mostrarMsjCambio = true
                this.msjCambio = "Cambiando la contraseña"*/
                axios({
                    method: "POST", 
                    "data": {
                        "nuevaPass": this.passwd
                    },
                    "url": url,
                    "headers": {
                        'Authorization': `${tokenUser}`
                    }
                }).then(result => {
                    
                    this.$notification.success(result.data, {  timer: 10, position:"bottomRight"});

                    this.msjCambiarPass = "Se ha cambiado la contraseña, inicie sesión nuevamente, Gracias!"

                    this.mostrarCambioPass = false
                    
                }, error => {
                    this.$notification.error(error.response.data, {  timer: 10, position:"bottomRight"});
                })
                .finally(() => {
                });


            }
        },
        volverLogin: function(){
            this.$router.push("Login");
        },
    
        validarCampos: function(mail){

            if(mail == "" || mail == null){
                this.mailValidoUser = false
            } else{
                this.mailValidoUser =  this.regMail.test(user.mailUsuario)
            }

        },

    }
  }
</script>
